export const FONT_WEIGHT = {
  BOLD: 700,
  NORMAL: 400
};

export const COLOUR_PALETTE = {
  DENIM: '#0E5AA7',
  SNOW: '#f9f9f9',
  ALICE_BLUE: '#F7F8F9',
  MAHOGANY: '#CE3637',
  NOBEL: '#979797',
  SHIRAZ: '#8A1538',
  NERO: '#1F1F1F',
  SMOKE_WHITE: '#F4F4F4',
  BRAND_RED: '#BC1E20',
  GRAY: '#DCDCDC',
  DARK_GRAY: '#747474'
};