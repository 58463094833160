import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import barcodeImg from 'assets/images/barcode.svg';
import { FONT_WEIGHT } from 'theme';

const BottomContent = ({ canNotScan }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" style={{ marginTop: canNotScan ? 12 : 34 }}>
        <img src={barcodeImg} alt="barcode" />
      </div>
      <p
        style={{ marginTop: 18, fontSize: 20, fontWeight: FONT_WEIGHT.BOLD }}
        className="text-center mb-0"
      >
        {!canNotScan ? t('SCAN_PAGE.SCAN_BR') : t('MANUAL_PAGE.ITEM_NOT_READABLE')}
      </p>
      <p
        style={{ marginTop: 8, fontSize: 14, fontWeight: FONT_WEIGHT.NORMAL }}
        className="px-5 text-center"
      >
        {!canNotScan ? t('SCAN_PAGE.DESC1') : t('MANUAL_PAGE.ENTER_BARCODE_MANUALLY')}
      </p>
    </>
  );
};

BottomContent.propTypes = {
  canNotScan: PropTypes.bool
};

export default BottomContent;