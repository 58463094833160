import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import LeftArrow from 'assets/images/left-arrow.svg';
import useScreenSize from 'use-screen-size';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'constants';
import { FONT_WEIGHT } from 'theme';

const ScannerPageLayout = () => {
  const size = useScreenSize();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();

  const onClickBack = () => {
    if (location.pathname?.includes('camera-permission-required')) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <div
      className="main-container-without-hf overflow-hidden"
      style={{
        direction: i18n.language === LANGUAGES.ARABIC.langCode ? 'rtl' : 'ltr',
        maxWidth: size.width > 480 ? 370 : 'auto'
      }}
    >
      <div className="d-flex align-items-center py-2">
        <div>
          <button className="btn" onClick={onClickBack}>
            <img src={LeftArrow} alt="left-arrow" width={8} height={15} style={{ marginLeft: '10px' }} />
          </button>
        </div>
        <div className="text-center" style={{ width: '78%', fontSize: 16, fontWeight: FONT_WEIGHT.BOLD }}>Price Checker</div>
      </div>
      <Outlet />
    </div>
  );
};

ScannerPageLayout.propTypes = {
  screenSize: PropTypes.object
};

export default ScannerPageLayout;
