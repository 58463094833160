import React from 'react';
import CryptoJS from 'crypto-js';
import { LANGUAGES, TOAST_ID } from 'constants';
import { SECRET_KEY } from 'constants';

export const getPopupObject = ({ t, i18n, onClose, size }) => {
  return {
    toastId: TOAST_ID,
    icon: <i className="bi bi-exclamation-octagon"></i>,
    position: 'top-center',
    hideProgressBar: true,
    theme: 'colored',
    autoClose: false,
    rtl: i18n.language === LANGUAGES.ARABIC.langCode,
    closeButton: (
      <span className="popup-close-label" onClick={onClose} role="button">
        {t('COMMON.CLOSE')}
      </span>
    ),
    bodyClassName: 'popup-msg-body',
    toastClassName: 'toast-class',
    style: {
      marginTop: size.width <= 480 ? 65 : 44,
      padding: -10,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: 5
    },
    closeOnClick: false,
    draggable: false
  };
};

export const encrypt = data => {
  return data ? CryptoJS.AES.encrypt(data, SECRET_KEY).toString() : null;
};

export const decrypt = data => {
  return data ? CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8) : null;
};
