import React from 'react';
import GeneralErrorSvg from 'assets/images/general-error.svg';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { FONT_WEIGHT } from 'theme';
import { useNavigate } from 'react-router-dom';

const GeneralError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onTryAgainClick = () => {
    navigate(-1);
  };

  return (
    <div className="d-flex flex-column align-items-center px-3 pb-3">
      <img src={GeneralErrorSvg} alt="camera-access" />
      <p
        className="text-center px-4"
        style={{ marginTop: 68, fontWeight: FONT_WEIGHT.BOLD, fontSize: 20 }}
      >
        {t('GENERAL_ERROR.TITLE')}
      </p>
      <p
        className="text-center"
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 16,
          fontWeight: FONT_WEIGHT.NORMAL,
          fontSize: 16,
          lineHeight: 1.5
        }}
      >
        {t('GENERAL_ERROR.DESC')}
      </p>
      <Button
        title={t('COMMON.TRY_AGAIN')}
        style={{ position: 'absolute', bottom: 48, left: 16, right: 16 }}
        onButtonClick={onTryAgainClick}
      />
    </div>
  );
};

export default GeneralError;
