import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IMAGE_URL, LANGUAGES } from 'constants';
import { COLOUR_PALETTE, FONT_WEIGHT } from 'theme';
import { useParams } from 'react-router-dom';
import ScanAgain from 'components/scan-again';

const ProductDetails = ({ data, barcode, onScanAgain }) => {
  const { t, i18n } = useTranslation();
  const { country } = useParams();
  const [showImage, setShowImage] = useState(true);
  return (
    <div
      className="px-0 d-flex flex-column align-items-center"
      style={{ marginTop: 16 }}
    >
      <div
        className="w-100"
        style={{ backgroundColor: COLOUR_PALETTE.ALICE_BLUE, paddingTop: 16, paddingBottom: 8 }}
      >
        {data?.item_loyalty_price &&
        <>
          <p style={{ fontSize: 12, fontWeight: FONT_WEIGHT.BOLD, color: COLOUR_PALETTE.NOBEL }} className="text-center m-0">
            {t('DETAILS_PAGE.UNIT_PRICE')}
          </p>
          <p style={{ marginTop: 4, fontSize: 24, lineHeight: 1 }} className="text-center mb-0">
            <s>
              <span style={{ fontWeight: FONT_WEIGHT.BOLD }}>{parseFloat(data?.item_selling_price).toFixed(2)}</span>
              <span> AED</span>
            </s>
          </p>
        </>
        }
        <p
          style={{ marginTop: 16, fontSize: 16, fontWeight: FONT_WEIGHT.BOLD, color: COLOUR_PALETTE.NOBEL, lineHeight: 1 }}
          className="text-center mb-0"
        >
          {data?.item_loyalty_price ? t('DETAILS_PAGE.SHARE_PRICE') : t('DETAILS_PAGE.UNIT_PRICE')}
        </p>
        <p style={{ fontSize: 40, fontWeight: FONT_WEIGHT.BOLD, color: data?.item_loyalty_price ? COLOUR_PALETTE.SHIRAZ : '#000' }} className="text-center m-0">
          <span style={{ fontSize: 40, fontWeight: FONT_WEIGHT.BOLD }}>{data?.item_loyalty_price ? parseFloat(data?.item_loyalty_price).toFixed(2) : parseFloat(data?.item_selling_price).toFixed(2)}</span>
          <span style={{ fontSize: 26, fontWeight: FONT_WEIGHT.NORMAL }}> AED</span>
        </p>
      </div>
      <div style={{ marginTop: 24, display: showImage ? 'block' : 'none' }}>
        <img src={`${IMAGE_URL}/smapp/${country}/${barcode}.jpg`} alt="product-img" onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          setShowImage(false);
        }} />
      </div>
      <div className="p-4">
        <p
          style={{
            marginTop: 16,
            fontSize: 20,
            fontWeight: FONT_WEIGHT.BOLD,
            lineHeight: 1.5,
            color: COLOUR_PALETTE.NERO
          }}
          className="text-center"
        >
          {i18n.language === LANGUAGES.ENGLISH.langCode ? data?.item_name_eng : data?.item_name_ar}
        </p>
        { data?.item_description &&
          <p
            style={{ marginTop: 8, fontSize: 14, fontWeight: FONT_WEIGHT.NORMAL, lineHeight: 1.5, color: COLOUR_PALETTE.NERO }}
            className="text-center mb-0"
          >
            {data?.item_description}
          </p>
        }
      </div>
      <ScanAgain onScanAgain={onScanAgain} />
    </div>
  );
};

ProductDetails.propTypes = {
  data: PropTypes.object,
  barcode: PropTypes.string.isRequired,
  onScanAgain: PropTypes.func.isRequired
};

export default ProductDetails;
