const getEnvValue = (key) => {
  return process.env[`REACT_APP_${key}`];
};

export const APP_NAME = getEnvValue('APP_NAME');

export const API_URL = getEnvValue('API_URL');

export const IMAGE_URL = getEnvValue('IMAGE_URL');

export const TOAST_ID = 'toastId';

export const LANGUAGES = {
  ENGLISH: {
    langCode: 'en',
    language: 'English'
  },
  ARABIC: {
    langCode: 'ar',
    language: 'عربى'
  }
};

export const DEAFULT_STORE = 'maf';

export const SECRET_KEY = getEnvValue('SECRET_KEY');
