import React, { useEffect, useRef, useState } from 'react';
import useScreenSize from 'use-screen-size';
import useLoader from 'hooks/useLoader';
import { getPrice } from 'services';
import { useTranslation } from 'react-i18next';
import useScanner from 'hooks/useScanner';
import { useNavigate, useParams } from 'react-router-dom';
import useConfig from 'hooks/useConfig';
import Button from 'components/button';
import ScanAgain from 'components/scan-again';
import 'react-spring-bottom-sheet/dist/style.css';
import Manual from './manual';
import ProductDetails from './product-details';
import BottomContent from './bottom-content';
import BottomSheet from './bottom-sheet';
import Overlay from 'components/overlay';
import CanNotScanAlert from './can-not-scan-alert';

let timer;
const Main = () => {
  const { t } = useTranslation();
  const sheetRef = useRef();
  const navigate = useNavigate();
  const config = useConfig();
  
  const { showLoader, hideLoader } = useLoader();
  const { isPermittedToUseCamera, initialize, qrcodeRegionId, pause, resume, stop } =
    useScanner();
  const { storecode } = useParams();
  const size = useScreenSize();

  const [showManualScr, setShowManualScr] = useState(false);
  const [showProductDetailsScr, setShowProductDetailsScr] = useState(false);
  const [productData, setProductData] = useState(null);
  const [canNotScan, setCanNotScan] = useState(false);
  const [barcode, setBarcode] = useState('');

  const onClose = () => {
    setCanNotScan(false);
    resume();
    showToastAndPauseCameraAfterScanTime();
  };

  const showToastAndPauseCameraAfterScanTime = () => {
    if (config?.scanningTime) {
      timer = setTimeout(() => {
        setBarcode('');
        setCanNotScan(true);
        pause(true);
      }, config?.scanningTime);
    }
  };

  const onScanResult = (decodedText, decodedResult) => {
    clearTimeout(timer);
    pause(true);
    setBarcode(decodedText);
    setTimeout(() => {
      callGetPrice(decodedText);
    }, 300);
  };

  useEffect(() => {
    if (productData) {
      setShowManualScr(false);
      setShowProductDetailsScr(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  useEffect(() => {
    initialize(
      {
        disableFlip: false,
        useBarCodeDetectorIfSupported: true,
        fps: config?.cameraFps || 10,
        qrbox: { width: size.width > 480 ? 300 : size.width - 48, height: 150 }
      },
      onScanResult
    );
    return () => {
      stop();
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPermittedToUseCamera) {
      showToastAndPauseCameraAfterScanTime();
    } else {
      clearTimeout(timer);
      navigate('/camera-permission-required');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPermittedToUseCamera]);

  const close = () => {
    setBarcode('');
    setCanNotScan(false);
    resume();
    showToastAndPauseCameraAfterScanTime();
  };

  const callGetPrice = (decodedText = '') => {
    const data = {
      barcode: barcode || decodedText || ''
    };
    const headers = {
      store_key: storecode
    };
    showLoader();
    getPrice(data, headers)
      .then((res) => {
        hideLoader();
        if (res?.data?.meta?.statusCode === '200') {
          setProductData(res?.data?.data);
        } else {
          navigate('/failed-to-retrive-information');
        }
      })
      .catch((e) => {
        hideLoader();
        navigate('/failed-to-retrive-information');
      });
  };

  const onManualSubmit = () => {
    callGetPrice();
  };

  const onClickManual = () => {
    clearTimeout(timer);
    setCanNotScan(false);
    pause(true);
    setShowManualScr(true);
  };

  const onScanAgain = () => {
    setProductData(null);
    setShowManualScr(false);
    setShowProductDetailsScr(false);
    close();
  };

  return (
    <div className="">
      <div id={qrcodeRegionId} />
      <BottomContent canNotScan={canNotScan} />
      <div className="d-flex flex-column align-items-center px-3">
        <Button title={t('SCAN_PAGE.BUTTON1')} onButtonClick={onClickManual} className="w-100 mb-4" />
      </div>
      {canNotScan && <ScanAgain onScanAgain={onScanAgain} />}

      <Overlay show={showManualScr || showProductDetailsScr} />

      <div className="position-relative oveflow-hidden">
        <BottomSheet
          ref={sheetRef}
          open={showManualScr}
          onDismiss={(event) => {
            setShowManualScr(false);
            close();
          }}
        >
          <Manual barcode={barcode} setBarcode={setBarcode} onManualSubmit={onManualSubmit} />
        </BottomSheet>
        <BottomSheet
          ref={sheetRef}
          open={showProductDetailsScr}
          onDismiss={(event) => {
            setShowProductDetailsScr(false);
            setProductData(null);
            close();
          }}
        >
          <ProductDetails barcode={barcode} data={productData} onScanAgain={onScanAgain} />
        </BottomSheet>
      </div>
      <CanNotScanAlert show={canNotScan} onClose={onClose} />
    </div>
  );
};

export default Main;
