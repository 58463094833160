import React, { useEffect, useState } from 'react';
import { getConfigData } from 'services';
import useLocalStorage from 'hooks/useLocalStorage';
import { Outlet } from 'react-router-dom';

const ConfigProtected = () => {
  const [config, setConfig] = useLocalStorage('conf');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const headers = {
      timestamp: config?.timeStamp ?? 0
    };
    getConfigData(headers)
      .then((res) => {
        if (config?.timeStamp === 0) {
          setConfig(res?.data?.data);
        } else {
          setConfig({ ...config, ...res?.data?.data });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div className="loading" style={{ display: 'block' }} />;
  }
  return <Outlet />;
};

export default ConfigProtected;
