import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'constants';
import { useNavigate, useParams } from 'react-router-dom';
import LandingImg from 'assets/images/landing-page.svg';
import { FONT_WEIGHT, COLOUR_PALETTE } from 'theme';
import { getStores } from 'services';
import Swal from 'sweetalert2';
import useLoader from 'hooks/useLoader';
import useConfig from 'hooks/useConfig';
import LocationError from 'pages/location-access';
import Button from 'components/button';

const LandingPage = () => {
  const { storecode = '', country = 'uae' } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const config = useConfig();
  const [isLocationPermissionEnabled, setIsLocationPermissionEnabled] = useState(true);
  const { showLoader, hideLoader } = useLoader();
  const [userNearStore, setUserNearStore] = useState(false);
  const [errors, setErrors] = useState({});
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStores] = useState(null);

  const callGetStoreAPI = useCallback((lat = 0, long = 0) => {
    const data = {
      langCode: i18n.language,
      lat,
      long
    };
    showLoader();
    getStores(data)
      .then((res) => {
        let storesFromApi = [];
        const foundStoresBasedOnLatLong = res?.data?.data?.found_stores?.stores;
        if (foundStoresBasedOnLatLong?.length > 0) {
          setUserNearStore(true);
          storesFromApi = foundStoresBasedOnLatLong;
        } else {
          if (config.locationModelEnabled) {
            setUserNearStore(false);
          } else {
            setUserNearStore(true);
          }
          storesFromApi = res?.data?.data?.all_stores?.stores;
        }

        if (storesFromApi.length) {
          setStores(
            storesFromApi.map((s) => ({
              label: `${s.store_key} ${s.store_name}`,
              value: s.store_key
            }))
          );

          const matchedStore = storesFromApi.filter((s) => s.store_key === storecode);
          if (matchedStore.length) {
            setSelectedStores({
              label: `${matchedStore[0].store_key} ${matchedStore[0].store_name}`,
              value: matchedStore[0].store_key
            });
          } else if (storesFromApi.length === 1) {
            setSelectedStores({
              label: `${storesFromApi[0].store_key} ${storesFromApi[0].store_name}`,
              value: storesFromApi[0].store_key
            });
          }
        }
        hideLoader();
      })
      .catch((e) => {
        hideLoader();
        Swal.fire(t('COMMON.ERROR'), t('ERROR.SOMETHING_WRONG_TRY_AGAIN'), 'error');
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationBasedStores = useCallback(() => {
    showLoader();
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setIsLocationPermissionEnabled(true);
          callGetStoreAPI(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          setIsLocationPermissionEnabled(false);
          hideLoader();
          navigate('/location-permission-required');
        },
        {
          enableHighAccuracy: true
        }
      );
    } else {
      setIsLocationPermissionEnabled(false);
      hideLoader();
      navigate('/location-permission-required');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.locationModelEnabled]);

  useEffect(() => {
    if (config) {
      if (config?.locationModelEnabled) {
        getLocationBasedStores();
      } else {
        navigate(`/${country}/${i18n.language}/${storecode}/pricechecker/scan`, {
          state: { fromLanding: true }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocationBasedStores, i18n.language, storecode]);

  const onContinue = () => {
    if (!selectedStore) {
      setErrors({ store: true });
      return false;
    }
    navigate(`/${country}/${i18n.language}/${selectedStore?.value}/pricechecker/scan`, {
      state: { fromLanding: true }
    });
  };

  const onStoreChage = (selected) => {
    if (!selected) {
      setErrors({ store: true });
    } else {
      setErrors({ store: false });
    }
    if (userNearStore) {
      setSelectedStores(selected);
    }
  };

  return (
    <div>
      {
        !isLocationPermissionEnabled ?
          <LocationError />
          :
          <>
            <img
              className="w-100 mt-2"
              src={LandingImg}
              alt="landing-images"
              style={{ objectFit: 'cover', direction: 'ltr !important' }}
            />
            <div className="px-3 mt-4 d-flex flex-column">
              <span
                className="d-inline"
                style={{ fontSize: 24, fontWeight: FONT_WEIGHT.BOLD, lineHeight: '32px' }}
              >
                {t('LANDING_PAGE.TITLE')}
              </span>
              <span
                className="d-inline"
                style={{
                  fontSize: 16,
                  marginTop: 4,
                  fontWeight: FONT_WEIGHT.NORMAL,
                  fontStyle: 'normal',
                  color: COLOUR_PALETTE.DARK_GRAY
                }}
              >
                {t('LANDING_PAGE.DESC')}
              </span>
            </div>
            <div className="px-3" style={{ marginTop: 20 }}>
              {stores.length === 1 && selectedStore ? (
                <div className="form-item">
                  <input
                    id="store"
                    className="form-control text-secondary"
                    readOnly
                    value={selectedStore?.label}
                    style={{ height: 48 }}
                  />
                  <label htmlFor="store" className="text-secondary">Store</label>
                </div>
              ) : (
                <>
                  {!userNearStore ? (
                    <div>
                      <p style={{ fontSize: 18, fontWeight: FONT_WEIGHT.BOLD }}>
                        {t('LANDING_PAGE.USER_OUT_OF_STORE_MSG')}
                      </p>
                      <ul className="list-group" style={{ height: 250, overflow: 'scroll', padding: 0 }}>
                        {stores.length > 0 &&
                        stores?.map((s, index) => (
                          // onDoubleClick added for developer option
                          <li key={index} className="list-group-item" onDoubleClick={() => index === 1 ? setUserNearStore(true) : null}>
                            {s.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <>
                      <Select
                        options={stores}
                        placeholder={t('LANDING_PAGE.SELECT_STORE')}
                        isRtl={i18n.language === LANGUAGES.ARABIC.langCode}
                        components={{ IndicatorSeparator: () => null }}
                        value={selectedStore}
                        onChange={onStoreChage}
                        isClearable
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 48,
                            border: errors?.store ? '1px solid #ff8b67' : '',
                            ':hover': {
                              border: errors?.store ? '1px solid #ff8b67' : ''
                            }
                          })
                        }}
                      />
                      {errors?.store && (
                        <span className="text-danger">{t('LANDING_PAGE.STORE_VAL_MSG')}</span>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {userNearStore && (
              <div className="px-3 pb-2">
                <Button title={t('COMMON.CONTINUE')} className="w-100 mt-3" onButtonClick={onContinue} />
              </div>
            )}
          </>
      }
    </div>
  );
};

export default LandingPage;
