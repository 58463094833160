import React from 'react';
import PropTypes from 'prop-types';
import { COLOUR_PALETTE, FONT_WEIGHT } from 'theme';

const Button = ({ style, title, onButtonClick, className, disabled }) => {
  return (
    <button
      className={`btn text-center text-light ${className}`}
      style={{
        backgroundColor: COLOUR_PALETTE.DENIM,
        fontWeight: FONT_WEIGHT.BOLD,
        height: 48,
        fontSize: 14,
        borderRadius: 12,
        ...style
      }}
      onClick={onButtonClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

Button.defaultProps = {
  onButtonClick: () => {}
};

Button.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;
