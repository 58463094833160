import React from 'react';
import carrefour from 'assets/images/carrefour.svg';
import downarrow from 'assets/images/arrow.svg';
import { LANGUAGES } from 'constants';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import CountryFlag from './country-flag';

const Header = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dir = i18n.language === LANGUAGES.ARABIC.langCode ? 'left' : 'right';
  const logoWidth = 133;
  const logoHeight = 35;

  const handleLanguageChange = (e) => {
    e.preventDefault();
    var url = location.pathname;
    var result= url.split('/');
    if (result.length > 2) {
      result.splice(2, 1, e.target.value);
      const newUrl = result.join('/');
      navigate(newUrl, {...location, replace: true });
    } else {
      i18n.changeLanguage(e.target.value);
    }
  };

  return (
    <>
      <div className="px-3" style={{ marginTop: 24 }}>
        <div className="row w-100 mx-0">
          <div className="col-md-6 col-sm-6 col-6 px-0">
            <div
              className={`d-flex justify-content-center align-items-center ${
                dir === 'right' ? 'float-start' : 'float-end'
              }`}
            >
              <img
                src={carrefour}
                alt="Logo"
                width={logoWidth}
                height={logoHeight}
                className="img"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-6 px-2">
            <div
              className={`d-flex justify-content-center align-items-center ${
                dir === 'left' ? 'float-start' : 'float-end'
              }`}
            >
              <CountryFlag />
              <select
                value={i18n.language}
                style={{
                  background: `url(${downarrow}) no-repeat ${dir} #fff`,
                  fontSize: 16,
                  height: 40
                }}
                onChange={handleLanguageChange}
                className="selectDropdown"
              >
                {Object.keys(LANGUAGES).map((lang, index) => {
                  return (
                    <option value={LANGUAGES[lang].langCode} key={index}>
                      {LANGUAGES[lang].language}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
