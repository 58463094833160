import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from 'use-screen-size';
import { useTranslation } from 'react-i18next';
import { FONT_WEIGHT, COLOUR_PALETTE } from 'theme';

const CanNotScanAlert = ({ show, onClose }) => {
  const size = useScreenSize();
  const { t } = useTranslation();
  return (
    show && 
    <div
      style={{
        position: 'absolute',
        top: '64px',
        width: size?.width > 420 ? '350px' : '-webkit-fill-available',
        marginLeft: '10px',
        marginRight: '10px'
      }}
    >
      <div
        className={`alert alert-dismissible fade ${
          show ? 'show' : 'hide'
        } d-flex align-items-center justify-content-between`}
        style={{
          padding: '12px 14px 12px 14px',
          height: 48,
          backgroundColor: COLOUR_PALETTE.MAHOGANY
        }}
        role="alert"
      >
        <div>
          <i
            className="bi bi-exclamation-octagon text-light"
            style={{ fontWeight: FONT_WEIGHT.BOLD }}
          ></i>
          <span
            className="text-light"
            style={{
              fontSize: 14,
              lineHeight: '20px',
              fontWeight: FONT_WEIGHT.NORMAL,
              marginLeft: 19
            }}
          >
            {t('SCAN_PAGE.SCAN_ERR')}
          </span>
        </div>
        <button
          type="button"
          className="btn text-light px-0"
          data-bs-dismiss="alert"
          onClick={onClose}
          style={{ fontSize: 14, lineHeight: '20px', fontWeight: FONT_WEIGHT.BOLD }}
          aria-label="Close"
        >
          {t('COMMON.CLOSE')}
        </button>
      </div>
    </div>
  );
};

CanNotScanAlert.defaultProps = {
  onClose: () => {}
};

CanNotScanAlert.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default CanNotScanAlert;
