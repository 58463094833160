import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  const showLoader = () => {
    setShow(true);
  };

  const hideLoader = () => {
    setShow(false);
  };
  return (
    <LoaderContext.Provider
      value={{
        loaderState: show,
        showLoader,
        hideLoader
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node
};

export default LoaderProvider;
