import React from 'react';
import PropTypes from 'prop-types';
import barcodeImg from 'assets/images/barcode-white.svg';
import { FONT_WEIGHT, COLOUR_PALETTE } from 'theme';
import { useTranslation } from 'react-i18next';

const ScanAgain = ({ onScanAgain }) => {
  const { t } = useTranslation();
  return (
    <div style={{ backgroundColor: COLOUR_PALETTE.SMOKE_WHITE }} className="w-100 d-flex justify-content-center align-items-center">
      <div style={{ border: `4px solid ${COLOUR_PALETTE.GRAY}`, borderRadius: 12, padding: 8, marginTop: 24, marginBottom: 16 }}>
        <button
          className="btn"
          style={{ backgroundColor: COLOUR_PALETTE.BRAND_RED, height: 80, borderRadius: 8, padding: 8 }}
          onClick={onScanAgain}
        >
          <img src={barcodeImg} alt="barcode" style={{ width: 40 }} />
          <p
            style={{ marginTop: 12, fontSize: 14, fontWeight: FONT_WEIGHT.BOLD, lineHeight: 1, color: COLOUR_PALETTE.NERO }}
            className="w-100 text-center mb-0 text-light"
          >
            {t('DETAILS_PAGE.SCAN_AGAIN')}
          </p>
        </button>
      </div>
    </div>
  );
};

ScanAgain.propTypes = {
  onScanAgain: PropTypes.func.isRequired
};

export default ScanAgain;
