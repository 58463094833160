import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languages = ['en', 'ar'];

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  whitelist: languages,
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: {
      translation: require('translations/en/translation.json')
    },
    ar: {
      translation: require('translations/ar/translation.json')
    }
  }
});

export default i18n;
