import React from 'react';
import { IMAGE_URL } from 'constants';
import { useParams } from 'react-router-dom';

const CountryFlag = () => {
  const { country } = useParams();

  return (
    <img
      src={`${IMAGE_URL}/pricecheckerapp/countryimages/${country || 'uae'}.svg`}
      alt={`${country} || uae`}
      className="selectImage"
    />
  );
};

export default CountryFlag;
