import { useState } from 'react';
import { encrypt, decrypt } from 'utils';

const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(decrypt(value));
      } else {
        if (defaultValue) {
          window.localStorage.setItem(keyName, encrypt(JSON.stringify(defaultValue)));
        }
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      if (newValue) {
        window.localStorage.setItem(keyName, encrypt(JSON.stringify(newValue)));
      } else {
        window.localStorage.removeItem(keyName);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
