import React from 'react';
import GeneralErrorSvg from 'assets/images/general-error.svg';
// import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { FONT_WEIGHT } from 'theme';

const UrlNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center px-3">
      <img src={GeneralErrorSvg} alt="not-found" />
      <p className="text-center" style={{ marginTop: 68, fontWeight: FONT_WEIGHT.BOLD, fontSize: 20 }}>{t('COMMON.URL_NOT_FOUND')}</p>
      <p className="text-center" style={{ paddingLeft: 10, paddingRight: 10, marginTop: 16, fontWeight: FONT_WEIGHT.NORMAL, fontSize: 16, lineHeight: 1.5 }}>
        {t('COMMON.URL_NOT_FOUND_DESC')}
      </p>
      {/* <Button title={t('COMMON.TRY_AGAIN')} style={{ marginTop: 170 }} /> */}
    </div>
  );
};

export default UrlNotFound;
