import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useParams } from 'react-router-dom';
import useScreenSize from 'use-screen-size';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'constants';

const ErrorPageLayout = () => {
  const size = useScreenSize();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <div
      className="main-container-without-hf overflow-hidden"
      style={{
        direction: i18n.language === LANGUAGES.ARABIC.langCode ? 'rtl' : 'ltr',
        maxWidth: size.width > 480 ? 370 : 'auto'
      }}
    >
      <Outlet />
    </div>
  );
};

ErrorPageLayout.propTypes = {
  screenSize: PropTypes.object
};

export default ErrorPageLayout;
