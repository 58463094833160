import React from 'react';
import PropTypes from 'prop-types';

function Overlay({ show }) {
  return (
    <div className="cu-overlay" style={{ display: show ? 'block' : 'none'}} />
  );
};

Overlay.propTypes = {
  show: PropTypes.bool
};

export default Overlay;