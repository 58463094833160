import React from 'react';
import CameraAccessImg from 'assets/images/location-access.svg';
// import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { FONT_WEIGHT } from 'theme';

const LocationError = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center px-3">
      <img src={CameraAccessImg} alt="camera-access" />
      <p
        className="text-center px-4"
        style={{ marginTop: 68, fontWeight: FONT_WEIGHT.BOLD, fontSize: 20 }}
      >
        {t('PERMISSION_DENIED.LOCATION.TITLE')}
      </p>
      <p
        className="text-center"
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 16,
          fontWeight: FONT_WEIGHT.NORMAL,
          fontSize: 16,
          lineHeight: 1.5
        }}
      >
        {t('PERMISSION_DENIED.LOCATION.DESC')}
      </p>
      {/* <Button
        title={t('COMMON.ALLOW')}
        style={{ marginTop: 170 }}
      /> */}
    </div>
  );
};

export default LocationError;
