import React from 'react';
import MajitLogo from 'assets/images/majid-logo.svg';

const Footer = () => {
  return (
    <div>
      <img src={MajitLogo} alt="sgnature" className="mafsignature" loading="lazy" />
    </div>
  );
};

export default Footer;
