import React, { createContext, useRef } from 'react';
import PropTypes from 'prop-types';

export const ScannerContext = createContext(null);

const ScannerProvider = ({ children }) => {
  let html5Qrcode = useRef(null);

  return (
    <ScannerContext.Provider
      value={{
        html5Qrcode
      }}
    >
      {children}
    </ScannerContext.Provider>
  );
};

ScannerProvider.propTypes = {
  children: PropTypes.node
};

export default ScannerProvider;
