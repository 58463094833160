import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import barcodeImg from 'assets/images/barcode.svg';
import { useTranslation } from 'react-i18next';
import { COLOUR_PALETTE, FONT_WEIGHT } from 'theme';
import useConfig from 'hooks/useConfig';
import Button from 'components/button';

const Manual = ({ barcode, setBarcode, onManualSubmit }) => {
  const { t } = useTranslation();
  const barcodeTextRef = useRef();
  const [errors, setErrors] = useState({});
  const config = useConfig();

  useEffect(() => {
    setTimeout(() => {
      barcodeTextRef.current.focus();
    }, 500);
  }, []);

  const onSubmitClick = () => {
    barcodeTextRef.current.blur();
    setTimeout(() => {
      onManualSubmit();
    }, 200);
  };

  return (
    <div className="px-3" style={{ marginTop: 16 }}>
      <div className="d-flex justify-content-center align-items-center">
        <img src={barcodeImg} alt="barcode" />
      </div>
      <p
        style={{ marginTop: 18, fontSize: 20, fontWeight: FONT_WEIGHT.BOLD }}
        className="text-center"
      >
        {t('MANUAL_PAGE.ITEM_NOT_READABLE')}
      </p>
      <p
        style={{ marginTop: 8, fontSize: 14, fontWeight: FONT_WEIGHT.NORMAL }}
        className="px-5 text-center"
      >
        {t('MANUAL_PAGE.ENTER_BARCODE_MANUALLY')}
      </p>
      <div className="form-item">
        <input
          id="barcode"
          type="number"
          ref={barcodeTextRef}
          className={`form-control p-3 ${errors?.barcodeLength ? 'border-danger' : ''}`}
          style={{
            marginTop: 28,
            fontWeight: FONT_WEIGHT.NORMAL,
            height: 48,
            width: '100%',
            fontSize: 16,
            boxShadow: 'none',
            border: `${
              barcode ? '1px solid ' + COLOUR_PALETTE.DARK_GRAY : '2px solid ' + COLOUR_PALETTE.GRAY
            }`
          }}
          onChange={(e) => {
            const val = e.target.value;
            if (val) {
              if (new RegExp(config?.regexForBarcode).test(val)) {
                if (val.length <= config?.barcodeLength) {
                  setErrors({ barcodeLength: false });
                } else {
                  setErrors({ barcodeLength: true });
                }
                setBarcode(val);
              }
            } else {
              setErrors({ barcodeLength: false });
              setBarcode('');
            }
          }}
          value={barcode}
          required
        />
        <label
          htmlFor="barcode"
          style={{ fontSize: 16, color: barcode ? '#000' : COLOUR_PALETTE.DARK_GRAY }}
          className={`${errors?.barcodeLength ? 'text-danger' : ''}`}
        >
          {t('SCAN_PAGE.ENTER_BARCODE')}
        </label>
      </div>
      {errors?.barcodeLength && (
        <p
          className="text-danger word-wrap mb-0"
          style={{ fontSize: 12, marginTop: 10, lineHeight: '16px' }}
        >
          <i className="bi bi-exclamation-circle-fill" style={{ marginRight: 5 }}></i>
          {t('MANUAL_PAGE.BARCODE_LENGTH_VALIDATION_MSG')}
        </p>
      )}
      <Button
        title={t('COMMON.SUBMIT')}
        onButtonClick={onSubmitClick}
        className="w-100 mb-3"
        disabled={!barcode || errors?.barcodeLength}
        style={{
          marginTop: 16,
          opacity: !barcode || errors?.barcodeLength ? 0.2 : 1
        }}
      />
    </div>
  );
};

Manual.propTypes = {
  barcode: PropTypes.string.isRequired,
  setBarcode: PropTypes.func.isRequired,
  onManualSubmit: PropTypes.func.isRequired
};

export default Manual;
