import axios from 'axios';
import { API_URL } from 'constants';

const headers = {
  'Content-Type': 'application/json',
  accept: 'application/json'
};

const axiosObj = axios.create({
  baseURL: `${API_URL}`,
  headers
});

export const getStores = (data) => {
  // return axiosObj.get('/v1/price_checker/getstorekey?latitude=25.1568297&longitude=56.348174', {
  return axiosObj.get(
    `/v1/price_checker/getpricecheckerstorekey?latitude=${data?.lat}&longitude=${data?.long}`,
    {
      headers: {
        ...axiosObj.defaults.headers,
        langCode: data?.langCode,
        appId: 'IOS',
        storeId: 'mafuae'
      }
    }
  );
};

export const getPrice = (data, headers) => {
  return axiosObj.post('/v1/price_checker/getproductdetails', data, {
    headers: { ...axiosObj.defaults.headers, ...headers }
  });
};

export const getConfigData = (headers) => {
  return axiosObj.get('/v1/price_checker/appconfig', {
    headers: { ...axiosObj.defaults.headers, ...headers }
  });
};
