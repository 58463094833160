import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Footer from './footer';
import Header from './header';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'constants';

const Layout = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <div
      className="main-container pb-4"
      style={{ direction: i18n.language === LANGUAGES.ARABIC.langCode ? 'rtl' : 'ltr' }}
    >
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
