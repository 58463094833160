import { useContext, useState } from 'react';
import { ScannerContext } from 'providers/ScannerProvider';
import { Html5Qrcode, Html5QrcodeScannerState, Html5QrcodeSupportedFormats } from 'html5-qrcode';

const createConfig = (props) => {
  let config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  config.experimentalFeatures = {
    useBarCodeDetectorIfSupported: true
  };
  config.rememberLastUsedCamera = true;
  config.aspectRatio = 0.9;
  config.formatsToSupport = [
    Html5QrcodeSupportedFormats.AZTEC,
    Html5QrcodeSupportedFormats.CODABAR,
    Html5QrcodeSupportedFormats.CODE_128,
    Html5QrcodeSupportedFormats.CODE_39,
    Html5QrcodeSupportedFormats.CODE_93,
    Html5QrcodeSupportedFormats.DATA_MATRIX,
    Html5QrcodeSupportedFormats.EAN_13,
    Html5QrcodeSupportedFormats.EAN_8,
    Html5QrcodeSupportedFormats.ITF,
    Html5QrcodeSupportedFormats.MAXICODE,
    Html5QrcodeSupportedFormats.PDF_417,
    Html5QrcodeSupportedFormats.QR_CODE,
    Html5QrcodeSupportedFormats.RSS_14,
    Html5QrcodeSupportedFormats.RSS_EXPANDED,
    Html5QrcodeSupportedFormats.UPC_A,
    Html5QrcodeSupportedFormats.UPC_E,
    Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION
  ];
  return config;
};

const qrcodeRegionId = 'html5qr-code-scanner';

const useScanner = () => {
  let { html5Qrcode } = useContext(ScannerContext);
  const [isPermittedToUseCamera, setIsPermittedToUseCamera] = useState(true);

  const getState = () => html5Qrcode?.current?.getState();

  const pause = (pauseVideo) => {
    if (html5Qrcode.current?.getState() === Html5QrcodeScannerState.SCANNING) {
      if (pauseVideo) {
        html5Qrcode?.current?.pause(true);
      } else {
        html5Qrcode?.current?.pause();
      }
      // Hide Scanner paused div
      let xpath = "//div[text()='Scanner paused']";
      let matchingElement = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      matchingElement.style.display = 'none';
    }
  };

  const stop = () => {
    if (
      getState() === Html5QrcodeScannerState.SCANNING ||
      getState() === Html5QrcodeScannerState.PAUSED
    ) {
      html5Qrcode?.current?.stop();
    }
  };

  const resume = () => {
    if (getState() === Html5QrcodeScannerState.PAUSED) {
      html5Qrcode?.current?.resume();
    }
  };

  const initialize = (props, successCallback) => {
    Html5Qrcode.getCameras()
      .then((cameras) => {
        setIsPermittedToUseCamera(true);
        if (cameras && cameras?.length) {
          html5Qrcode.current = new Html5Qrcode(qrcodeRegionId);
          const config = createConfig(props);

          if (!successCallback) {
            // eslint-disable-next-line no-throw-literal
            throw 'qrCodeSuccessCallback is required callback.';
          }
          html5Qrcode.current
            .start({ facingMode: 'environment' }, config, successCallback)
            .catch((e) => {});
        }
      })
      .catch((err) => {
        setIsPermittedToUseCamera(false);
      });
  };

  return {
    isPermittedToUseCamera,
    initialize,
    getState,
    resume,
    pause,
    stop,
    html5Qrcode: html5Qrcode?.current,
    qrcodeRegionId
  };
};

export default useScanner;
