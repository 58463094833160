import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LoaderProvider from 'providers/LoaderProvider';
import ScannerProvider from 'providers/ScannerProvider';

import Main from 'pages/scanner-page';
import LandingPage from 'pages/landing-page';
import UrlNotFound from 'pages/not-found';

import Layout from 'components/layout';
import ScannerPageLayout from 'components/scanner-page-layout';
import ErrorPageLayout from 'components/error-page-layout';

import Loader from 'components/loader';
import ConfigProtected from 'components/config-protected';
import GeneralError from 'pages/general-error';
import CameraError from 'pages/camera-access';
import LocationError from 'pages/location-access';

const NavigationE = () => {
  return (
    <BrowserRouter>
      <LoaderProvider>
        <ScannerProvider>
          <Routes>
            <Route element={<ConfigProtected />}>
              <Route element={<Layout />}>
                <Route path="/" element={<LandingPage />} />
                <Route path="/:country/:lang/:storecode/pricechecker" element={<LandingPage />} />
              </Route>
              <Route element={<ScannerPageLayout />}>
                <Route
                  exact
                  path="/:country/:lang/:storecode/pricechecker/scan"
                  element={<Main />}
                />
                <Route exact path="/failed-to-retrive-information" element={<GeneralError />} />
                <Route exact path="/camera-permission-required" element={<CameraError />} />
                <Route exact path="/location-permission-required" element={<LocationError />} />
              </Route>
              <Route element={<ErrorPageLayout />}>
                <Route path="*" element={<UrlNotFound />} />
              </Route>
            </Route>
          </Routes>
          <Loader />
        </ScannerProvider>
      </LoaderProvider>
    </BrowserRouter>
  );
};

export default NavigationE;
