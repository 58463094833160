import React from 'react';
import useLoader from 'hooks/useLoader';

const Loader = () => {
  const { loaderState } = useLoader();

  return <div className="loading" style={{ display: loaderState ? 'block' : 'none' }} />;
};

export default Loader;
