import React from 'react';
import PropTypes from 'prop-types';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

const BottomSheetEx = ({ children, onDismiss, open, ref }) => {
  return (
    <BottomSheet
      ref={ref}
      open={open}
      blocking={false}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
      onDismiss={onDismiss}
    >
      {children}
    </BottomSheet>
  );
};

BottomSheetEx.defaultProps = {
  onDismiss: () => {}
};

BottomSheetEx.propTypes = {
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func,
  open: PropTypes.bool,
  ref: PropTypes.string
};

export default BottomSheetEx;